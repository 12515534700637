import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher({ availableLanguages = ['fr-FR'] }) {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languageMap = {
    'fr-FR': '🇫🇷 Français',
    'en-EN': '🇬🇧 English',
    'de-DE': '🇩🇪 Deutsch',
  };

  const getLanguageLabel = (code) => languageMap[code] || `🌐 ${code}`;

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const resolvedLang = i18n.resolvedLanguage;
    return availableLanguages.includes(resolvedLang)
      ? resolvedLang
      : availableLanguages[0];
  });

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    setIsOpen(false);
  };

  useEffect(() => {
    const resolvedLang = i18n.resolvedLanguage;
    if (availableLanguages.includes(resolvedLang)) {
      setCurrentLanguage(resolvedLang);
    } else {
      setCurrentLanguage(availableLanguages[0]);
    }
  }, [i18n.resolvedLanguage, availableLanguages]);

  return (
    <div className="fixed top-4 right-4 z-[200]">
      {/* Trigger for Dropdown */}
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center bg-gray-100 rounded-lg py-1 px-2 shadow-md hover:bg-gray-200"
      >
        {getLanguageLabel(currentLanguage).split(' ')[0]}{' '}
        {/* Show only the flag */}
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute top-full right-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
          {availableLanguages.map((code) => (
            <button
              type="button"
              key={code}
              onClick={() => changeLanguage(code)}
              className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 text-sm"
            >
              <span>{getLanguageLabel(code).split(' ')[0]}</span> {/* Flag */}
              <span>{getLanguageLabel(code).split(' ')[1]}</span> {/* Label */}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
