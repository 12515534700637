import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
import environment from '../../../environments/environment';
import clap from '../../ressources/pictures/clap.svg';
import gift from '../../ressources/pictures/gift2.svg';

function SponsorShip({ customStyle, sharedGift, handleShareClickSponsorShip }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="title-modal">
        <div className="flex items-center justify-center gap-2">
          <img style={{ width: '10%' }} src={clap} alt="gift" />
          {t('congratulations')}
          <img
            style={{ width: '10%', transform: 'scaleX(-1)' }}
            src={clap}
            alt="gift"
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <p>{t('participation_saved')}</p>
      </div>
      <img style={{ margin: '20px 0', width: '30%' }} src={gift} alt="gift" />
      <p
        style={{
          padding: '0 20px',
          fontWeight: '600',
          textAlign: 'center',
          marginBottom: '16px',
        }}
      >
        {t('share_friend')}
        {sharedGift}
      </p>
      <button
        className="google-button"
        type="button"
        style={{
          maxWidth: '100%',
          background: customStyle?.buttonColor,
        }}
        onClick={handleShareClickSponsorShip}
      >
        <span className="google-button__text">{t('send_friend')}</span>
      </button>
    </div>
  );
}

function ClaimForm({ customStyle, getRedeemText, sendFormCTAConfirmation }) {
  const { t } = useTranslation();
  
  return (
    <div
      style={{
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p className="core-text">
        {t('get_gift_use')} <b>{getRedeemText()}</b> {t('click_get_gift')}
      </p>
      <img style={{ margin: '16px 0', width: '30%' }} src={gift} alt="gift" />
      <button
        className="google-button"
        type="button"
        style={{
          margin: '20px 0',
          maxWidth: '100%',
          background: customStyle?.buttonColor,
        }}
        onClick={sendFormCTAConfirmation}
      >
        <span className="google-button__text">{t('get_gift')}</span>
      </button>
    </div>
  );
}

function ClaimDelayed({
  formData,
  pickedCta,
  wheel,
  getRedeemText,
  sendFormCTAConfirmation,
  handleChange,
  handleMarketingChange,
  checkEmailValidity,
  handleSubmit,
  setFormData,
  customStyle,
  shopName,
}) {
  const { t } = useTranslation();
  if (pickedCta.type === 'form') {
    return (
      <ClaimForm
        customStyle={customStyle}
        getRedeemText={getRedeemText}
        sendFormCTAConfirmation={sendFormCTAConfirmation}
      />
    );
  }

  return (
    <>
      {wheel?.gameType !== 'raffle' ? (
        <p className="core-text">
          {t('to_get_gift')} <b>{getRedeemText()}</b>
          &nbsp;
          {wheel?.wheelCampaignField?.mail &&
            !wheel?.wheelCampaignField?.phone &&
            t('errors.email_required')}
          {wheel?.wheelCampaignField?.phone &&
            !wheel?.wheelCampaignField?.mail &&
            t('errors.phone_required')}
          {wheel?.wheelCampaignField?.phone &&
            wheel?.wheelCampaignField?.mail &&
            t('errors.phone_email_required')}
        </p>
      ) : (
        <p className="core-text">
          {t('validate_participation')} <b>{t('your_email')}</b>
        </p>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          width: '90%',
        }}
      >
        {wheel?.wheelCampaignField?.mail && (
          <input
            type="text"
            id="mail"
            name="mail"
            className="custom-form"
            placeholder="E-mail"
            autoComplete="off"
            value={formData.mail}
            onBlur={checkEmailValidity}
            onChange={handleChange}
            required
          />
        )}
        {wheel?.wheelCampaignField?.phone && (
          <PhoneInput
            country="fr"
            regions="europe"
            value={formData.phone}
            onChange={(phone) => {
              setFormData((prev) => ({ ...prev, phone }));
            }}
            inputStyle={{
              width: '100%',
              height: '50px',
            }}
            className="w-full"
            placeholder={t('phone')}
            autoComplete="off"
            required
          />
        )}
        <span
          style={{
            fontType: 'italic',
            color: 'red',
            visibility: formData.isMailInvalid ? 'visible' : 'hidden',
          }}
        >
          {t('errors.invalid_email')}
        </span>
        <div className="marketing">
          <input
            type="checkbox"
            id="marketing"
            name="marketing"
            value={formData.marketing}
            onChange={handleMarketingChange}
          />
          <label htmlFor="marketing">
            {t('accept_promotional_offers_from')} {shopName}
          </label>
        </div>
      </div>

      <button
        className="google-button"
        type="button"
        onClick={handleSubmit}
        disabled={formData.isMailInvalid}
        style={{
          background: customStyle?.buttonColor,
        }}
      >
        <span className="google-button__text">{t('get_gift')}</span>
      </button>
    </>
  );
}

function ClaimImmediate({
  pickedCta,
  sharedGift,
  handleShareClickSponsorShip,
  customStyle,
}) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p className="core-text">{t('get_gift_sreen')}</p>
      <img style={{ margin: '16px 0', width: '20%' }} src={gift} alt="gift" />
      {pickedCta.sponsorship ? (
        <>
          <p className="core-text mb-4">
            {t('share_friend')} <b>{sharedGift}</b>
          </p>
          <button
            className="google-button"
            type="button"
            style={{
              maxWidth: '100%',
              background: customStyle?.buttonColor,
            }}
            onClick={handleShareClickSponsorShip}
          >
            <span className="google-button__text">{t('send_friend')}</span>
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

function SimpleConfirmation() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="title-modal">
        <div className="flex items-center justify-center gap-2">
          <img style={{ width: '10%' }} src={clap} alt="gift" />
          {t('congratulations')}
          <img
            style={{ width: '10%', transform: 'scaleX(-1)' }}
            src={clap}
            alt="gift"
          />
        </div>
      </div>
      <p style={{ textAlign: 'center' }}>{t('participation_saved_confirm')}</p>
      <img style={{ margin: '20px 0', width: '50%' }} src={gift} alt="gift" />
    </div>
  );
}

function MailForm(props) {
  const [formData, setFormData] = useState({
    mail: '',
    phone: '',
    firstName: '',
    lastName: '',
    marketing: false,
    isMailInvalid: false,
    isSent: false,
  });

  const {
    wheelId,
    shop,
    wheelHistoryId,
    pickedCta,
    uuid,
    giftRecived,
    wheel,
    isLose,
    user,
    sharedGift,
  } = props;

  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMarketingChange = () => {
    setFormData((prev) => ({ ...prev, marketing: !prev.marketing }));
  };

  const handleShareClickSponsorShip = async () => {
    const sharedData = {
      title: t('share_to_friend'),
      text: `${t('friend_good_deal')} ${props?.sharedGift} ${t('to')} ${
        props?.name
      } : http://app.dokaa.app?qrCode=${props?.sharedWheel}`,
    };
    await navigator.share(sharedData);
  };

  const getRedeemText = () => {
    const { redeemAvailableDays } = props?.wheel || {};
    if (redeemAvailableDays === 0) return t('now');
    if (redeemAvailableDays > 0) return t('next_order');
    return t('next_visit');
  };

  const checkEmailValidity = (e) => {
    const isValid = validator.isEmail(e.target.value);
    setFormData((prev) => ({ ...prev, isMailInvalid: !isValid }));
  };

  const notify = (text) => {
    toast.error(text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      wheelId,
      shopId: shop._id,
      user: {
        ...(wheel?.wheelCampaignField?.mail && { mail: formData.mail }),
        ...(wheel?.wheelCampaignField?.phone && {
          phone: formData.phone,
        }),
      },
      wheelCtaType: pickedCta.type,
      marketing: formData.marketing,
      userSeed: uuid,
      gift: {
        name: giftRecived.name,
        ...(giftRecived.digitalOffer && {
          digitalOffer: giftRecived.digitalOffer,
        }),
        ...(giftRecived.discountType && {
          discountType: giftRecived.discountType,
        }),
      },
    };

    try {
      await axios.post(
        `${environment.REACT_APP_API_URL}/wheels-history/${wheelHistoryId}`,
        data
      );
      setFormData((prev) => ({ ...prev, isSent: true }));
    } catch (err) {
      console.error(err.response.data.message);
      notify(err.response.data.message);
    }
  };

  const sendFormCTAConfirmation = async () => {
    const data = {
      wheelId,
      shopId: shop._id,
      user: {
        mail: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      },
      userSeed: uuid,
      wheelCtaType: pickedCta.type,
      marketing: true,
      gift: {
        name: giftRecived.name,
        ...(giftRecived.digitalOffer && {
          digitalOffer: giftRecived.digitalOffer,
        }),
        ...(giftRecived.discountType && {
          discountType: giftRecived.discountType,
        }),
      },
    };

    try {
      await axios.post(
        `${environment.REACT_APP_API_URL}/wheels-history/${wheelHistoryId}`,
        data
      );
      setFormData((prev) => ({ ...prev, isSent: true }));
    } catch (err) {
      console.error(err.response.data.message);
      notify(err.response.data.message);
    }
  };

  if (isLose) {
    return (
      <div className="mail-form">
        <div className="title-modal">{t('sorry')}...</div>
        <p>{t('lost_gift')}</p>
      </div>
    );
  }

  return (
    <div className="mail-form">
      {formData.isSent ? (
        <div>
          {pickedCta.sponsorship ? (
            <SponsorShip
              customStyle={wheel?.customStyle}
              sharedGift={sharedGift}
              handleShareClickSponsorShip={handleShareClickSponsorShip}
            />
          ) : (
            <SimpleConfirmation />
          )}
        </div>
      ) : (
        <>
          <div className="title-modal">
            {t('congratulations_gift')}
            <br />
            <span
              className="title-modal-alt"
              style={{ color: wheel?.customStyle?.buttonColor }}
            >
              {' '}
              {giftRecived?.digitalOffer
                ? `-${giftRecived?.name}${
                    giftRecived?.discountType === 'percent'
                      ? '%'
                      : t('currency')
                  }`
                : giftRecived?.name}
            </span>
          </div>
          {wheel.claimImediate ? (
            <ClaimImmediate
              pickedCta={pickedCta}
              handleShareClickSponsorShip={handleShareClickSponsorShip}
              sharedGift={sharedGift}
              customStyle={wheel?.customStyle}
            />
          ) : (
            <ClaimDelayed
              formData={formData}
              pickedCta={pickedCta}
              wheel={wheel}
              getRedeemText={getRedeemText}
              sendFormCTAConfirmation={sendFormCTAConfirmation}
              handleChange={handleChange}
              handleMarketingChange={handleMarketingChange}
              checkEmailValidity={checkEmailValidity}
              handleSubmit={handleSubmit}
              setFormData={setFormData}
              customStyle={wheel?.customStyle}
              shopName={shop.name}
            />
          )}

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </>
      )}
    </div>
  );
}
export default MailForm;
