import React from 'react';

function Card({ card, customStyle, shop, flipped, shuffle, handleClick, translatedItem }) {
  return (
    <div
      onClick={() => (flipped ? undefined : handleClick(card.name, card.id))}
      className={`card ${flipped ? 'flipped' : ''} ${shuffle ? 'shuffle' : ''}`}
      style={{ border: `3px solid ${customStyle.cardBackBorderColor}` }}
      role="button"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleClick(card.name, card.id);
      }}
    >
      <div className="back" style={{ background: customStyle?.cardBackColor }}>
        <img src={shop.logo} alt="shop logo" />
      </div>
      <div
        className="front"
        style={{
          background: customStyle?.cardFrontColor,
          color: customStyle?.cardContentColor,
        }}
      >
        <img src={card.icon} alt="card icon" />
        {translatedItem}
      </div>
    </div>
  );
}

export default Card;
