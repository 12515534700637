import { useEffect, useState } from 'react';
import './index.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import appendData from '../../redux/action';
import environment from '../../../environments/environment';

const notify = (text) => {
  toast.error(text, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

function Cards(props) {
  const { t } = useTranslation();

  const shuffle = (originalArray, selectedItem) => {
    const array = Array(9).fill(originalArray[selectedItem]);

    const middleIndex = Math.floor(array.length / 2);
    const gameBoard = document.getElementById('game-board');
    const middleElement = gameBoard.children[middleIndex];

    const childElements = Array.from(gameBoard.children);

    childElements.forEach((originalElement, index) => {
      const element = originalElement;
      element.style.transition = 'all 1s ease';
      element.style.transform = `translateX(${
        middleElement.offsetLeft - element.offsetLeft
      }px) translateY(${middleElement.offsetTop - element.offsetTop}px)`;

      // Reset styles after the animation completes
      setTimeout(() => {
        element.style.transition = '';
        element.style.transform = '';
      }, 1000);
    });

    return array;
  };

  const {
    style,
    items,
    triggerWheel,
    onSelectItem,
    shop,
    setDisplaySecondModal,
    wheel,
    uuid,
    pickedCta,
    wheelHistoryId,
    selectedItem,
    translatedItems,
  } = props;

  const [cardList, setCardList] = useState(() =>
    items.map((item, index) => ({
      ...item,
      id: index,
      name: item.digitalOffer
        ? `-${item.name}${
            item.discountType === 'percent' ? '%' : t('currency')
          }`
        : item.name,
      flipped: false,
    }))
  );

  const [flippedCard, setFlippedCard] = useState({});
  const [gameOver, setGameOver] = useState(false);
  const [startedGame, setStartedGame] = useState(false);
  const [startShuffle, setStartShuffle] = useState(false);

  const handleClick = (name, index) => {
    if (Object.keys(flippedCard).length === 0) {
      const currentCard = { name, index };
      setFlippedCard(currentCard);
      onSelectItem(cardList[index]);
      setTimeout(() => {
        setDisplaySecondModal(true);
        if (wheel.claimImediate) {
          const data = {
            wheelId: wheel._id,
            userSeed: uuid,
            wheelCtaType: pickedCta.type,
            marketing: false,
            gift: {
              name: cardList[index].name,
              ...(cardList[index].digitalOffer && {
                digitalOffer: cardList[index].digitalOffer,
              }),
              ...(cardList[index].discountType && {
                discountType: cardList[index].discountType,
              }),
            },
          };

          axios
            .post(
              `${environment.REACT_APP_API_URL}/wheels-history/${wheelHistoryId}`,
              data
            )
            .catch((err) => {
              console.log(err.response.data.message);
              notify(err.response.data.message);
            });
        }
      }, 2000);
    }
  };

  useEffect(() => {
    setCardList(
      items.map((item, index) => ({
        ...item,
        id: index,
        name: item.digitalOffer
          ? `-${translatedItems[item]}${
              item.discountType === 'percent' ? '%' : t('currency')
            }`
          : translatedItems[index],
        flipped: false,
      }))
    );
  }, [items]);

  const startGame = () => {
    setStartedGame(true);
    setFlippedCard({});
    setGameOver(false);
  };

  useEffect(() => {
    if (triggerWheel) {
      startGame();
    }
  }, [triggerWheel]);

  useEffect(() => {
    if (startedGame) {
      const shuffledCards = shuffle(items, selectedItem);
      setTimeout(() => {
        setStartShuffle(true);
        setCardList(
          shuffledCards.map((item, index) => ({
            ...item,
            id: index,

            name: item.digitalOffer
              ? `-${translatedItems[index]}${
                  item.discountType === 'percent' ? '%' : t('currency')
                }`
              : translatedItems[index],
            flipped: false,
          }))
        );
        setTimeout(() => {
          setStartShuffle(false);
        }, 1000);
      }, 2000);
    }
  }, [startedGame]);

  return (
    <div id="game-board">
      {!gameOver &&
        cardList.map((card, index) => (
          <Card
            key={card.id}
            card={card}
            translatedItem={translatedItems[index]}
            shop={shop}
            customStyle={style}
            flipped={index === flippedCard.index || !startedGame}
            shuffle={startShuffle}
            handleClick={() => handleClick(card.name, index)}
          />
        ))}
    </div>
  );
}

const mapDispatchToProps = {
  appendData,
};

const mapStateToProps = (state) => ({
  isModaleDisplayed: state.isModaleDisplayed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
