import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './index.css';
import environment from '../../../environments/environment';

const dayjs = require('dayjs');

export default function ClaimGift() {
  const [history, setHistory] = useState(undefined);
  const [wheel, setWheel] = useState(undefined);
  const [shop, setShop] = useState(undefined);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isAlreadyUsed, setIsAlreadyUsed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [shopCode, setShopCode] = useState('');

  const { t } = useTranslation();

  const { search } = useLocation();
  const gift = new URLSearchParams(search).get('gift');

  useEffect(() => {
    axios
      .get(`${environment.REACT_APP_API_URL}/wheels-history/${gift}`)
      .then((res) => {
        setIsAlreadyUsed(res.data.isGiftReceived);
        setHistory(res.data);
        setWheel(res.data.wheelId);
        setShop(res.data.wheelId.shopId);
        setIsMounted(true);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      //
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    axios
      .put(
        `${environment.REACT_APP_API_URL}/wheels-history/${history._id}/claim-gift`,
        {
          shopCode,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setIsClaimed(true);
        }, 1000);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };
  return (
    <div>
      {isMounted ? (
        <div className="claim-gift">
          <div className="container">
            <div className="brand-header">
              <div className="logo">
                <img className="brand-logo" src={shop.logo} alt="Logo" />
              </div>
              <div className="brand-name">{shop.name}</div>
            </div>

            {isClaimed && (
              <div className="content">
                <p>{t('gift_pickup_confirm')}</p>
              </div>
            )}

            {!isClaimed && isAlreadyUsed && (
              <div className="content">
                <p>{t('gift_already_claimed')} </p>
              </div>
            )}

            {!isClaimed && !isAlreadyUsed && (
              <div>
                <div className="content">
                  <p>
                    {t('congratulations_won')}{' '}
                    <span className="gift-name-text">
                      {history?.gift?.name}
                    </span>{' '}
                    {t('to_pickup_to')}{' '}
                    <span className="brand-name-text">{shop.name}</span> ! 🎁
                  </p>
                  <p>{t('pickup_gift_step')}</p>
                  <ol className="number-list">
                    <li className="indexed">
                      {t('pickup_gift_step_share_screen')} 📲
                    </li>
                    <li className="indexed">
                      {t('pickup_gift_step_enter_code')} ✅
                    </li>
                  </ol>
                </div>
              </div>
            )}

            {!isClaimed && !isAlreadyUsed ? (
              <div className="claim-gift-footer">
                {wheel?.minimumOrder > 0 && (
                  <span className="warning-text">
                    {t('min_order')} {wheel?.minimumOrder}
                    {t('currency')} {t('non_cumulative')}
                    <br />
                  </span>
                )}
                {dayjs().isBefore(dayjs(history?.availableDate), 'day') ? (
                  <div>
                    <p>
                      {t('available_gift_date')}{' '}
                      <span className="gift-name-text">
                        {dayjs(history.availableDate).format('DD/MM/YYYY')}{' '}
                        {t('cannot_claim_gift')}{' '}
                      </span>{' '}
                    </p>
                  </div>
                ) : (
                  <span className="warning-text">{t('staff_member_fill')}</span>
                )}
                {dayjs().isAfter(dayjs(history?.maxAvailableDate), 'day') ? (
                  <div>
                    <p>
                      {t('late_available_gift')}{' '}
                      <span className="gift-name-text">
                        {dayjs(history.maxAvailableDate).format('DD/MM/YYYY')}{' '}
                        {t('cannot_claim_gift_late')}
                      </span>{' '}
                    </p>
                  </div>
                ) : (
                  <span className="warning-text" />
                )}

                <input
                  type="text"
                  id="mail"
                  name="mail"
                  className="custom-form"
                  placeholder={t('enter_shop_code')}
                  autoComplete="off"
                  value={shopCode}
                  onChange={(e) => setShopCode(e.target.value)}
                  required
                />
                <span
                  style={{
                    fontType: 'italic',
                    color: 'red',
                  }}
                >
                  {errorMessage}
                </span>
                <button
                  className="google-button"
                  type="button"
                  disabled={
                    dayjs().isBefore(dayjs(history.availableDate), 'day') ||
                    dayjs().isAfter(dayjs(history.maxAvailableDate), 'day')
                  }
                  style={{
                    width: '100%',
                    background: wheel?.customStyle?.buttonColor,
                  }}
                  onClick={handleClick}
                >
                  <span className="google-button__text">
                    {t('staff_gift_claimed')}
                  </span>
                </button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
