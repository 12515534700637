import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ClaimGift from './components/claim-gift';
import Wheel from './components/wheel';
import appendData from './redux/action';

import './App.css';

import '@fontsource/montserrat';
import Cards from './components/cards';
import Modal from './components/modal';
import MailForm from './components/mail-form';
import FirstModalForm from './components/firstModalForm';
import environment from '../environments/environment';
import Links from './components/links/Links';
import LanguageSwitcher from './components/LanguageSwitcher';
import i18n from './utils/i18n';

const addWheelTranslations = (response) => {
  const { wheel } = response;
  Object.entries(wheel.translation.languages).forEach(
    ([languageCode, translations]) => {
      if (translations.offers) {
        const offersObject = translations.offers.reduce((acc, offer, index) => {
          acc[`offer_${index}`] = offer; // Generate unique keys like offer_0, offer_1, etc.
          return acc;
        }, {});

        i18n.addResourceBundle(
          languageCode,
          'dynamic',
          { offers: offersObject },
          true,
          false
        );
      }

      i18n.addResourceBundle(
        languageCode,
        'dynamic',
        translations.texts,
        true,
        false
      );
    }
  );


  const defaultOffersObject = wheel.offers.reduce((acc, offer, index) => {
    acc[`offer_${index}`] = offer.name;
    return acc;
  }, {});

  const defaultFrenchTranslations = {
    title: wheel.customText.primaryText,
    description: wheel.customText.secondaryText,
    button: wheel.customText.buttonText,
    offers: defaultOffersObject,
  };

  i18n.addResourceBundle(
    'fr-FR',
    'dynamic',
    defaultFrenchTranslations,
    true,
    false
  );
};

function Home(props) {
  const [items, setItems] = useState([]);
  const [wheel, setWheel] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [selectedGift, setSelectedGift] = useState(undefined);
  const [displayFirstModal, setDisplayFirstModal] = useState(true);
  const [displaySecondModal, setDisplaySecondModal] = useState(false);
  const [triggerWheel, setTriggerWheel] = useState(false);
  const [shop, setShop] = useState({});
  const [wheelId, setWheelId] = useState({});
  const [customStyle, setCustomStyle] = useState(undefined);
  const [customText, setCustomText] = useState(undefined);
  const [pickedCta, setPickedCta] = useState('');
  const [gameType, setGameType] = useState('');
  const [token, setToken] = useState('');
  const [user, setUser] = useState({});
  const [wheelHistoryId, setWheelHistoryId] = useState('');
  const { search } = useLocation();
  const qrCode = new URLSearchParams(search).get('qrCode');

  const { t } = useTranslation();

  const generateRandomToken = () =>
    `${Date.now()}${Math.floor(Math.random() * 100)}`;

  const retrieveUniqueToken = () => {
    const tokenRetrieved = Cookies.get('id');
    if (tokenRetrieved) return tokenRetrieved;
    const randomToken = generateRandomToken();
    Cookies.set('id', randomToken, { expires: 182 });
    return randomToken;
  };

  const onSelectItem = (gift) => {
    setSelectedItem(gift.id);
    setSelectedGift(gift);
  };

  const { setHasTranslations, setAvailableLanguages } = props;

  useEffect(() => {
    const tokenL = retrieveUniqueToken();
    setToken(tokenL);
    axios
      .post(`${environment.REACT_APP_API_URL}/wheels/randomWheels/${qrCode}`, {
        uuid: tokenL,
      })
      .then((res) => {
        setItems(res.data.wheel.offers);
        setWheel(res.data.wheel);
        setSelectedItem(res.data.selectedItem);
        setShop(res.data.wheel.shopId);
        setWheelId(res.data.wheel._id);
        setCustomStyle(res.data.wheel?.customStyle);
        setCustomText(res.data.wheel?.customText);
        setPickedCta(res.data?.pickedCta);
        setGameType(res.data?.wheel?.gameType);
        setWheelHistoryId(res.data.wheelHistoryId);

        // Extract available languages from the response
        const translations = res.data.wheel.translation?.languages || {};
        const languages = [
          'fr-FR',
          ...Object.keys(translations).filter((lang) => lang !== 'fr-FR'),
        ];

        if (languages.length > 1) {
          setAvailableLanguages(languages);
          setHasTranslations(true);
        } else {
          setAvailableLanguages(['fr-FR']);
          i18n.changeLanguage('fr-FR');
          setHasTranslations(false);
        }

        addWheelTranslations(res.data);
      })
      .catch((err) => {
        console.log(err);
        i18n.changeLanguage('fr-FR');
        setAvailableLanguages(['fr-FR']);
        setHasTranslations(false);
      });
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: customStyle?.backgroundColor }}
        className="upperContainer"
      >
        <div className="container">
          <div className="preTitle">
            <h1 style={{ color: customStyle?.primaryTextColor }}>
              {t('dynamic:title', "")}
            </h1>
            <p style={{ color: customStyle?.secondaryTextColor }}>
              {t('dynamic:description', "")}
            </p>
          </div>
          {(gameType === 'wheel' || gameType === 'raffle') && (
            <>
              <button
                className="clickToTurnWheel"
                type="button"
                style={{
                  color: customStyle?.buttonTextColor,
                  '--wheel-button-color': customStyle?.buttonColor,
                }}
                disabled={triggerWheel}
                onClick={() => setTriggerWheel(true)}
              >
                {t('dynamic:button')}
              </button>
              <div className="roue">
                <Wheel
                  user={user}
                  style={customStyle}
                  shop={shop}
                  items={items}
                  translatedItems={items.map(
                    (item, index) =>
                      t(`dynamic:offers.offer_${index}`) || item.name
                  )}
                  pickedCta={pickedCta}
                  wheel={wheel}
                  uuid={token}
                  selectedItem={selectedItem}
                  triggerWheel={triggerWheel}
                  setTriggerWheel={setTriggerWheel}
                  onSelectItem={onSelectItem}
                  setDisplayFirstModal={setDisplayFirstModal}
                  setDisplaySecondModal={setDisplaySecondModal}
                  wheelHistoryId={wheelHistoryId}
                />
              </div>
            </>
          )}
          {gameType === 'cards' && (
            <>
              <button
                className="clickToShuffle"
                type="button"
                style={{
                  color: customStyle?.buttonTextColor,
                  '--wheel-button-color': customStyle?.buttonColor,
                }}
                disabled={triggerWheel}
                onClick={() => setTriggerWheel(true)}
                aria-disabled={triggerWheel}
              >
                {!triggerWheel ? t('dynamic:button') : t('choose_card')}
              </button>
              <div className="cards">
                <Cards
                  style={customStyle}
                  items={items}
                  translatedItems={items.map(
                    (item, index) =>
                      t(`dynamic:offers.offer_${index}`) || item.name
                  )}
                  pickedCta={pickedCta}
                  wheel={wheel}
                  uuid={token}
                  triggerWheel={triggerWheel}
                  setTriggerWheel={setTriggerWheel}
                  onSelectItem={onSelectItem}
                  shop={shop}
                  setDisplayFirstModal={setDisplayFirstModal}
                  setDisplaySecondModal={setDisplaySecondModal}
                  wheelHistoryId={wheelHistoryId}
                  selectedItem={selectedItem}
                />
              </div>
            </>
          )}

          <div className="modale">
            {pickedCta.type !== 'none' && (
              <Modal display={displayFirstModal}>
                <FirstModalForm
                  wheel={wheel}
                  shop={shop}
                  setUser={setUser}
                  setDisplayFirstModal={setDisplayFirstModal}
                  displayFirstModal={displayFirstModal}
                  customStyle={customStyle}
                  pickedCta={pickedCta}
                />
              </Modal>
            )}
            {selectedGift !== undefined && (
              <Modal display={displaySecondModal}>
                <MailForm
                  name={wheel?.shopId?.name}
                  wheelId={wheelId}
                  shop={shop}
                  giftRecived={selectedGift}
                  isLose={items[selectedItem]?.isLose}
                  uuid={token}
                  user={user}
                  customStyle={customStyle}
                  pickedCta={pickedCta}
                  sharedWheel={wheel?.sharedWheel}
                  sharedGift={wheel?.sharedGift}
                  wheel={wheel}
                  wheelHistoryId={wheelHistoryId}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
      <footer
        style={{
          textAlign: 'center',
          paddingHorizontal: 5,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="footer"
      >
        <a
          style={{ color: customStyle?.primaryTextColor, fontSize: '10px' }}
          href="https://www.dokaa.app/dokaa-conditions-de-participation"
          target="_blank"
          rel="noreferrer"
        >
          {t('terms_and_conditions')}
        </a>
      </footer>
    </>
  );
}

function App() {
  const [hasTranslations, setHasTranslations] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState(['fr-FR']);

  return (
    <Router>
      {hasTranslations && (
        <LanguageSwitcher availableLanguages={availableLanguages} />
      )}
      <div>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                setHasTranslations={setHasTranslations}
                setAvailableLanguages={setAvailableLanguages}
              />
            }
          />
          <Route exact path="/links/:slug" element={<Links />} />
          <Route exact path="/claim-gift" element={<ClaimGift />} />
        </Routes>
      </div>
    </Router>
  );
}

const mapDispatchToProps = {
  appendData,
};

const mapStateToProps = (state) => ({
  wheelId: state.wheelId,
  giftRecived: state.giftRecived,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
