/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import MoonLoader from 'react-spinners/MoonLoader';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import square from '../../ressources/pictures/square.png';
import stars from '../../ressources/pictures/etoiles.png';
import gift from '../../ressources/pictures/gift2.svg';
import 'react-phone-input-2/lib/style.css';

const socialTypes = ['facebook', 'instagram', 'tiktok'];
const integrationTypes = [
  'tripadvisor',
  'pagesjaunes',
  'deliveroo',
  'trustpilot',
  'google',
];

function FirstModalForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const { t } = useTranslation();

  function openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
    }).click();
  }

  useEffect(() => {
    if (props?.pickedCta) {
      setLoaded(true);
    }
  }, [props]);

  const openLink = (type) => {
    if (type === 'custom') {
      openInNewTab(props?.wheel?.customCounterpartLink);
    } else if (socialTypes.includes(type)) {
      const socialUrl = props.shop.socials[type];
      openInNewTab(socialUrl);
    } else if (integrationTypes.includes(type)) {
      openInNewTab(props.shop.integrations[props?.pickedCta.type].reviewUrl);
    }
  };

  const handleClick = (e) => {
    const type = props?.pickedCta?.type;
    e.preventDefault();
    openLink(type);
    setIsLoading(true);
    setTimeout(() => {
      props.setDisplayFirstModal(false);
    }, 15000);
  };

  const handleClickMessage = (e) => {
    e.preventDefault();
    openLink(props?.pickedCta.type);
  };

  const handleSocialClick = () => {
    openLink(props?.pickedCta.type);
    setIsLoading(true);
    setTimeout(() => {
      props.setDisplayFirstModal(false);
    }, 15000);
  };
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(t('phone_required')),
  });

  if (!loaded) {
    return null;
  }


  const type = props?.pickedCta?.type;
  const displayNameType =
    (type || '').charAt(0).toUpperCase() + (type || '').slice(1);

  return (
    <div className="first-modal-form">
      {isLoading ? (
        <>
          <div className="title-modal">
            {t('patience')} <br /> {t('few_more_seconds')}
          </div>
          {integrationTypes.includes(type) && (
            <div style={{ fontSize: '12px', margin: 'auto' }}>
              {t('verifying_review')}
            </div>
          )}
          {socialTypes.includes(type) && (
            <div style={{ fontSize: '12px', margin: 'auto' }}>
              {' '}
              {t('verifying_subscription')}
            </div>
          )}
          <div className="loading-spinner">
            <MoonLoader color="#f3c144" size={30} speedMultiplier={0.4} />
          </div>
          <p style={{ fontSize: '12px', padding: 'none' }}>
            {socialTypes.includes(type) && (
              <>
                {t('you_are_not_subscribed')}
                <br />
              </>
            )}
            {integrationTypes.includes(type) && (
              <>
                {t('not_reviewed_yet')}
                <br />
              </>
            )}
          </p>
          {props?.pickedCta.type !== 'form' && (
            <button
              className="google-button"
              disabled={!props.shop}
              style={{
                maxWidth: '100%',
                background: props?.customStyle?.buttonColor,
              }}
              onClick={handleClickMessage}
              type="button"
            >
              {socialTypes.includes(type) && (
                <span className="google-button__text">{t('subscribe')}</span>
              )}
              {integrationTypes.includes(type) && (
                <span className="google-button__text">
                  {t('give_review')}{' '}
                  {(props?.pickedCta.type || '').charAt(0).toUpperCase() +
                    (props?.pickedCta.type || '').slice(1)}
                </span>
              )}

              {props?.pickedCta.type === 'custom' && (
                <span className="google-button__text">
                  {t('go_back_to_page')}
                </span>
              )}
            </button>
          )}
        </>
      ) : (
        <>
          {props.shop.subscriptions.games.enable ? (
            <>
              {!(
                props.wheel.limitedRedem &&
                props.wheel.numberRedemed >= props.wheel.limitOfRedem
              ) ? (
                <>
                  {integrationTypes.includes(type) && (
                    <>
                      <div className="title-modal">{t('review_matter')}</div>
                      <div className="subtitle-modal">
                        {t('let_us_review_on')}{' '}
                        <strong>
                          {(props?.pickedCta.type.split('')[0].toUpperCase() ||
                            '') + (props?.pickedCta.type.slice(1) || '')}
                        </strong>{' '}
                        {t('leave_with_gift')}{' '}
                        <img
                          className="gift-icon"
                          src={gift}
                          alt="square"
                          style={{ left: '50%', transform: 'translateX(-50%)' }}
                        />
                      </div>
                    </>
                  )}

                  {socialTypes.includes(type) && (
                    <>
                      <div className="title-modal">
                        {t('subscription_matter')}
                      </div>
                      <div className="subtitle-modal">
                        {t('subscribe_account')}{' '}
                        <strong>{displayNameType}</strong>{' '}
                        {t('leave_with_gift')}{' '}
                        <img className="gift-icon" src={gift} alt="square" />
                      </div>
                    </>
                  )}

                  {props?.pickedCta.type === 'custom' && (
                    <>
                      <div className="title-modal">{t('good_deals')}</div>
                      <div className="subtitle-modal">
                        {t('follow_steps_to_join')}{' '}
                        <img className="gift-icon" src={gift} alt="square" />
                      </div>
                    </>
                  )}
                  {props?.pickedCta.type === 'noMoreCta' && (
                    <div className="title-modal">{t('already_played')}</div>
                  )}
                  {props?.pickedCta.type === 'wheelCooldown' && (
                    <div className="title-modal">{t('already_played_24h')}</div>
                  )}
                  {props?.pickedCta.type === 'form' && (
                    <div>
                      <div className="title-modal">
                        {t('fill_form_to_play')}
                      </div>
                      <Formik
                        initialValues={{
                          lastName: '',
                          firstName: '',
                          phone: '',
                          email: '',
                        }}
                        onSubmit={async (values) => {
                          const formInvalid = Object.values(values).some(
                            (value) => value === null || value === ''
                          );
                          setIsFormInvalid(formInvalid);

                          if (!formInvalid) {
                            props?.setUser(values);
                            setIsLoading(true);
                            setTimeout(() => {
                              props.setDisplayFirstModal(false);
                            }, 1000);
                          }
                          return null;
                        }}
                        validationSchema={validationSchema}
                      >
                        {({ errors, touched, setFieldValue, values }) => (
                          <Form
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Field
                              class="custom-form"
                              id="lastName"
                              name="lastName"
                              placeholder={t('last_name')}
                            />
                            <Field
                              class="custom-form"
                              id="firstName"
                              name="firstName"
                              placeholder={t('first_name')}
                            />

                            <PhoneInput
                              country="fr"
                              regions={['europe']}
                              value={values.phone}
                              onChange={(phone) =>
                                setFieldValue('phone', phone)
                              }
                              containerClass="mb-3"
                              inputStyle={{
                                width: '100%',
                                height: '50px',
                              }}
                              placeholder={t('phone')}
                            />

                            <Field
                              class="custom-form"
                              id="email"
                              name="email"
                              placeholder={t('email')}
                              type="email"
                            />
                            {errors.phone && touched.phone ? (
                              <div style={{ color: 'red' }}>{errors.phone}</div>
                            ) : null}
                            <button
                              className="google-button"
                              type="submit"
                              style={{
                                marginTop: '20px',
                                width: '100%',
                                background: props?.customStyle?.buttonColor,
                              }}
                            >
                              <span className="google-button__text">
                                {t('play')}
                              </span>
                            </button>
                            <span
                              style={{
                                fontSize: '12px',
                                padding: 'none',
                              }}
                            >
                              {t('accept_promotional_offers')}
                            </span>
                          </Form>
                        )}
                      </Formik>

                      {isFormInvalid && (
                        <p
                          style={{
                            color: '#df545d',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {t('fill_all_fields')}
                        </p>
                      )}
                    </div>
                  )}
                  {props?.pickedCta.type !== 'noMoreCta' &&
                    props?.pickedCta.type !== 'wheelCooldown' &&
                    props?.pickedCta.type !== 'form' && (
                      <>
                        <ol className="number-list first-list my-auto">
                          {integrationTypes.includes(type) && (
                            <li className="indexed flex items-center">
                              {t('let_us_review')}
                              <img
                                className="h-8 ml-2"
                                src={stars}
                                alt="square"
                              />
                            </li>
                          )}
                          {socialTypes.includes(type) && (
                            <li className="indexed">{t('subscribe')}</li>
                          )}
                          {props?.pickedCta.type === 'custom' && (
                            <li className="indexed">{t('follow_steps')}</li>
                          )}
                          {props?.pickedCta.type === 'krys' && (
                            <li className="indexed flex items-center">
                              {t('let_us_review')}
                              <img
                                className="h-8 ml-2"
                                src={stars}
                                alt="square"
                              />
                            </li>
                          )}
                          <li className="indexed flex items-center">
                            {t('come_back_on_click')}
                            <img
                              className="h-4 ml-2"
                              src={square}
                              alt="square"
                            />
                          </li>
                          <li className="indexed">
                            <div className="flex items-center">
                              {t('turn_wheel_discover')}
                              <img className="h-4" src={gift} alt="square" />
                            </div>
                          </li>
                        </ol>
                        {socialTypes.includes(type) && (
                          <button
                            className="google-button"
                            disabled={!props.shop}
                            style={{
                              maxWidth: '100%',
                              background: props?.customStyle?.buttonColor,
                            }}
                            onClick={handleSocialClick}
                            type="button"
                          >
                            <span className="google-button__text">
                              {t('subscribe')}
                            </span>
                          </button>
                        )}
                        {!socialTypes.includes(type) && (
                          <button
                            type="button"
                            className="google-button"
                            disabled={!props.shop}
                            style={{
                              maxWidth: '100%',
                              background: props?.customStyle?.buttonColor,
                            }}
                            onClick={handleClick}
                          >
                            {props.wheel && (
                              <>
                                {integrationTypes.includes(type) && (
                                  <span className="google-button__text">
                                    {t('let_review')}
                                  </span>
                                )}

                                {props?.pickedCta.type === 'custom' && (
                                  <span className="google-button__text">
                                    {t('start')}
                                  </span>
                                )}
                              </>
                            )}
                          </button>
                        )}
                      </>
                    )}
                </>
              ) : (
                <>
                  <div className="title-modal">{t('no_longer_available')}</div>
                  <div className="subtitle-modal">{t('retry')}</div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="title-modal">{t('no_longer_available')}</div>
              <div className="subtitle-modal">{t('retry')}</div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FirstModalForm;
